import React from "react"
import Header from "./header"
import Footer from "./footer"
//import layoutStyles from "./layout.module.css"

export default function Layout(props){
    return(
        <div className="row">
            <Header location={props.location} image={props.image} page_title={props.page_title}/>
            <div className="flex content">
                {props.children}
            </div>
            
            <Footer/>
        </div>
    )
}

import React from "react"
import footerStyles from "./footer.module.css"
import {Link} from "gatsby"
export default function Footer(props){
    return(
        <div className={footerStyles.footer}>
                <ul className={footerStyles.footer_ul}>
                    <li><Link className={footerStyles.nav} to='/'>Home</Link></li>
                    <li><Link className={footerStyles.nav} to='/about'>About</Link></li>
                    <li><Link className={footerStyles.nav} to='/contact'>Contact</Link></li>
                    <li><Link className={footerStyles.nav} to='/careers'>Careers</Link></li>
                    <li><Link className={footerStyles.nav} to='/portfolio'>Portfolio</Link></li>
                </ul>
                {/*<Link className={footerStyles.nav} to='/blackthorn'>- New Blackthorn Development -</Link>*/}
                <br/>
            <address>1657 Commerce Dr Suite #16A, South Bend, IN 46628</address>
            {/*<<address>mail: PO Box 3344, South Bend IN 46619</address>*/}

            <p>&copy; ADM Hotel and Resorts Group</p>
        </div>

    )
}
import React from "react"
import headerStyles from "./header.module.css"
import {Link} from "gatsby"

function renderHomepage(){


    return(
        <header>
            <div style={{backgroundColor:"red",color:'white',fontWeight:'bold',textAlign:'center',padding:'8px 0px 8px 0px'}}>We Apologize For Any Project Delays And Are Working Hard To Maintain Schedule. Due to Covid-19 &amp; Covid-19 Variants, Project Developments and Communications Have Been Delayed.</div>
            <div className={headerStyles.dark_overlay}>
                <div className={headerStyles.header_wrapper}>
                    <div className={headerStyles.logo}>
                        <Link to="/">
                            <img src="https://admhotels.com/images/adm_logo.png" alt=""/>
                        </Link>
                        
                    </div>

                    <ul className={headerStyles.wrapper__nav__ul}>
                    <li><Link className={headerStyles.nav} to='/'>Home</Link></li>
                        <li><Link className={headerStyles.nav} to='/about'>About</Link></li>
                        <li><Link className={headerStyles.nav} to='/contact'>Contact</Link></li>
                        <li><Link className={headerStyles.nav} to='/careers'>Careers</Link></li>
                        <li><Link className={headerStyles.nav} to='/portfolio'>Portfolio</Link></li>
                    </ul>
                </div>
                <div className={headerStyles.welcome}>
                    <div className={headerStyles.welcome_wrapper}>
                        <p className={headerStyles.heading1}>A NEW ERA OF LUXURY HOSPITALITY</p>
                        <p className={headerStyles.heading2}>ADM Hotels and Resorts Group</p>
                        <p className={headerStyles.heading3}>1657 Commerce Dr Suite #16A, South Bend, IN 46628<br/>Phone: 312-933-4642</p>
                        <a href="/#learnmore" className={headerStyles.header_button}>Learn More</a> 
                        
                        <div className={headerStyles.header_border_left}></div>
                        <div className={headerStyles.header_border_right}></div>
                    </div>
                </div>
                <div className={headerStyles.header_lowerfade}></div>
            </div>     
        </header>
    )
}

function renderContent(props){
    return(
        <div className={headerStyles.wrapper2}>
                        <div style={{zIndex:999,backgroundColor:"red",color:'white',fontWeight:'bold',textAlign:'center',padding:'8px 0px 8px 0px',width:'100%'}}>We Apologize For Any Project Delays And Are Working Hard To Maintain Schedule. Due to Covid-19 &amp; Covid-19 Variants, Project Developments and Communications Have Been Delayed.</div>
            <div className={headerStyles.header_wrapper}>
                <div className={headerStyles.logo}>
                    <Link to="/">
                        <img src="https://admhotels.com/images/adm_logo.png" alt=""/>
                    </Link>
                    
                </div>
                <ul className={headerStyles.wrapper__nav__ul}>
                    <li><Link className={headerStyles.nav} to='/'>Home</Link></li>
                    <li><Link className={headerStyles.nav} to='/about'>About</Link></li>
                    <li><Link className={headerStyles.nav} to='/contact'>Contact</Link></li>
                    <li><Link className={headerStyles.nav} to='/careers'>Careers</Link></li>
                    <li><Link className={headerStyles.nav} to='/portfolio'>Portfolio</Link></li>
                </ul>

            </div>
            <div className={headerStyles.darken}></div>
                <img src={props.image} className={headerStyles.banner_img} alt=""/>
                <div className={headerStyles.header_child}>
                    <div className={headerStyles.welcome_wrapper2}>
                        <h1 className={headerStyles.wrapper2_h1}>{props.page_title}</h1>
                        <div className={headerStyles.header_border_left}></div>
                        <div className={headerStyles.header_border_right}></div>
                        <div className={headerStyles.wrapper2_svg}>

                        
                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink"
	                        width="150px" height="auto" fill="white" viewBox="0 0 900 900"  xml="preserve">
                            <g>
                                <g>
                                    <path d="M381.0835,431.9963c0,7.3482-10.3817,8.8842-12.5662,1.9296H89.8694c16.3792,24.3141,2.3155,28.575,12.8673,37.467
                                        H92.1774c-3.1493,9.4942-3.1648,19.7601,0,29.2928c-1.8138,2.4853-5.4726,2.4623-7.2712,0c2.8252-9.5867,2.8175-19.7446,0-29.2928
                                        H74.347c11.3698-9.6022-2.964-16.657,12.6897-37.467c-0.3241,0-0.6252-0.0618-0.957-0.2393
                                        c-23.8509,15.8158-28.1041,2.0377-36.9343,12.5122v-10.567c-9.4942-3.1493-19.7523-3.1648-29.2928,0
                                        c-2.4856-1.8138-2.4546-5.4648,0-7.2634c9.5712,2.8175,19.729,2.8175,29.2928,0v-10.567
                                        c9.5482,11.308,16.5801-2.7864,37.1041,12.4193c0.3703-0.1543,0.6019-0.1543,0.9648-0.1543
                                        c-16.3792-24.3141-2.3155-28.5673-12.8673-37.4593h10.5593c3.1493-9.502,3.1648-19.7601,0-29.2928
                                        c1.8138-2.4931,5.4726-2.4546,7.2712,0c-2.8252,9.5867-2.8175,19.7446,0,29.2928h10.5593
                                        c-11.3698,9.5945,2.964,16.657-12.6898,37.4593h278.4703C370.7018,423.1195,381.0835,424.6481,381.0835,431.9963z"/>
                                    <path d="M120.4653,407.0751c130.6093,25.1508,197.2189,17.5264,237.4623,17.5264c5.5418,0,10.0344-4.4926,10.0344-10.0343
                                        s-4.4926-10.0343-10.0344-10.0343c-6.7892,0-11.6434,6.6243-9.5469,13.118c-71.8468,6.5415-146.5751,1.665-219.3236-16.1952
                                        c3.8657-6.6551-0.9315-15.0619-8.6749-15.0619c-8.4285,0-13.7801,10.2533-6.8776,17.117
                                        C115.6943,405.6895,117.5078,406.5055,120.4653,407.0751z"/>
                                    <path d="M353.9112,439.3332c-14.1672,0.2684-111.4171-7.1942-225.9276,16.123c-6.3222,1.2873-14.5372,1.3995-17.0733,8.7079
                                        c-2.0584,5.9315,1.908,13.4401,9.4712,13.4401c7.7285,0,12.5494-8.3919,8.6749-15.0619
                                        c68.8498-16.9031,143.4245-23.1057,219.3236-16.1952c-2.0985,6.4998,2.7631,13.118,9.5469,13.118
                                        c5.5418,0,10.0344-4.4925,10.0344-10.0343c0-3.3861-1.7618-6.6101-4.6084-8.4434
                                        C360.5604,439.1883,357.1003,439.2727,353.9112,439.3332z"/>
                                </g>
                                <g>
                                    <path d="M482.9165,431.9963c0,7.3482,10.3817,8.8842,12.5662,1.9296h278.6479c-16.3793,24.3141-2.3155,28.575-12.8673,37.467
                                        h10.5593c3.1493,9.4942,3.1648,19.7601,0,29.2928c1.8138,2.4853,5.4725,2.4623,7.2712,0c-2.8253-9.5867-2.8174-19.7446,0-29.2928
                                        h10.5593c-11.3699-9.6022,2.964-16.657-12.6898-37.467c0.3241,0,0.6252-0.0618,0.9571-0.2393
                                        c23.8509,15.8158,28.1041,2.0377,36.9343,12.5122v-10.567c9.4941-3.1493,19.7523-3.1648,29.2927,0
                                        c2.4857-1.8138,2.4546-5.4648,0-7.2634c-9.5712,2.8175-19.7291,2.8175-29.2927,0v-10.567
                                        c-9.5482,11.308-16.5801-2.7864-37.1041,12.4193c-0.3707-0.1543-0.6019-0.1543-0.9648-0.1543
                                        c16.3792-24.3141,2.3155-28.5673,12.8673-37.4593h-10.5593c-3.1494-9.502-3.1649-19.7601,0-29.2928
                                        c-1.8138-2.4931-5.4725-2.4546-7.2712,0c2.8253,9.5867,2.8174,19.7446,0,29.2928h-10.5593
                                        c11.3698,9.5945-2.964,16.657,12.6897,37.4593H495.4827C493.2982,423.1195,482.9165,424.6481,482.9165,431.9963z"/>
                                    <path d="M743.5347,407.0751c-130.6093,25.1508-197.219,17.5264-237.462,17.5264c-5.5418,0-10.0343-4.4926-10.0343-10.0343
                                        s4.4926-10.0343,10.0343-10.0343c6.7893,0,11.6434,6.6243,9.5469,13.118c71.8467,6.5415,146.5751,1.665,219.3236-16.1952
                                        c-3.8657-6.6551,0.9315-15.0619,8.6749-15.0619c8.4272,0,13.781,10.2523,6.8776,17.117
                                        C748.306,405.6888,746.4941,406.5052,743.5347,407.0751z"/>
                                    <path d="M510.0885,439.3332c14.1672,0.2684,111.4174-7.1942,225.9276,16.123c6.3223,1.2873,14.5372,1.3995,17.0734,8.7079
                                        c2.0587,5.9319-1.908,13.4401-9.4712,13.4401c-7.7289,0-12.5494-8.3919-8.675-15.0619
                                        c-68.8498-16.9031-143.4244-23.1057-219.3236-16.1952c2.0984,6.4998-2.7631,13.118-9.5469,13.118
                                        c-5.5418,0-10.0343-4.4925-10.0343-10.0343c0-3.3861,1.7617-6.6101,4.6083-8.4434
                                        C503.4393,439.1886,506.8994,439.2727,510.0885,439.3332z"/>
                                </g>
                                <path d="M439.6652,434.2251l23.5243-0.9396l-23.5243-0.9396c11.2973-3.3702,21.7945-5.1504,31.3702-5.099l6.2071-8.6613
                                    l-10.1126-3.3589c-7.7166,5.6699-17.2557,10.3998-28.3762,14.3137l18.4793-14.5873l-19.5838,13.0672
                                    c7.1587-9.3668,14.6048-16.9773,22.3819-22.5643l-0.0692-10.6557l-10.1556,3.227c-2.9103,9.1229-7.8472,18.5562-14.5437,28.259
                                    l6.3759-22.6632l-8.1629,22.0827c0.2859-11.7857,1.8368-22.3194,4.8445-31.4106l-6.3193-8.5798l-6.3193,8.5798
                                    c3.008,9.0909,4.5585,19.6246,4.8445,31.4106l-8.1629-22.0827l6.3759,22.6633c-6.6961-9.7031-11.6331-19.1365-14.5433-28.2591
                                    l-10.1556-3.2269l-0.0695,10.6556c7.7771,5.5867,15.2229,13.1972,22.3818,22.5643l-19.5838-13.0672l18.4793,14.5873
                                    c-11.1204-3.9139-20.6596-8.6439-28.3762-14.3137l-10.1126,3.3589l6.2074,8.6613c9.5754-0.0515,20.0729,1.7288,31.3699,5.099
                                    l-23.5243,0.9396l23.5243,0.9396c-11.2973,3.3702-21.7945,5.1504-31.3699,5.099l-6.2074,8.6613l10.1126,3.3589
                                    c7.7169-5.6699,17.2557-10.3998,28.3762-14.3137l-18.4793,14.5873l19.5838-13.0672c-7.159,9.3668-14.6048,16.9772-22.3818,22.5643
                                    l0.0692,10.6556l10.1559-3.2269c2.9103-9.1225,7.8472-18.5559,14.5433-28.2591l-6.3759,22.6633l8.1622-22.0824
                                    c-0.2859,11.7857-1.8365,22.3194-4.8445,31.4106L432,480.856l6.3193-8.5798c-3.0076-9.0912-4.5585-19.6249-4.8445-31.4106
                                    l8.1629,22.0827l-6.3759-22.6633c6.6961,9.7028,11.6331,19.1365,14.5437,28.2591l10.1556,3.2269l0.0695-10.6556
                                    c-7.7771-5.5867-15.2232-13.1972-22.3819-22.5643l19.5838,13.0672l-18.4796-14.5873c11.1208,3.9139,20.6595,8.6439,28.3761,14.3137
                                    l10.1126-3.3589l-6.2071-8.6613C461.4597,439.3752,450.9625,437.595,439.6652,434.2251z M432,438.1122
                                    c-2.6658,0-4.827-2.1612-4.827-4.827s2.1612-4.827,4.827-4.827c2.6657,0,4.8266,2.1612,4.8266,4.827
                                    S434.6658,438.1122,432,438.1122z"/>
                            </g>
                        </svg>
                        </div>
                        
                    </div>
                    
                </div>
                
        </div>






    )
}

function toRender(props){
    if(props.location === '/'){
        return renderHomepage();
    }else{
        return renderContent(props);
    }
}

export default function Header(props){


    return(
        <div>
            {toRender(props)}
        </div>

    )
} 